@import 'colors';
@import 'mixins';

/**
 * @tokens 00 Config I — @solublestudio/soluto-design-system
 */

$theme-colors: (
    'primary': $primary,
    'primary-dark': $primary-dark,
    'primary-darker': $primary-darker,
    'base-000': $base-000,
    'base-200': $base-200,
    'base-300': $base-300,
    'base-400': $base-400,
    'base-1000': $base-1000,
    'success': $support-success,
    'error': $support-error,
    'alert': $support-alert,
    'transparent': transparent,
    'secondary': $secondary,
);

$var-text-color: var(--text-color, $base-000);
$var-bg-color: var(--bg-color, $base-1000);

$var-link-color: var(--link-color, $primary-dark);
$var-link-hover-color: var(--link-hover-color, $primary-darker);
// $var-line-color: var(--line-color, $base-000);

$body-color: $var-text-color;
$body-bg: $var-bg-color;

// $font-size-base: $b300;

// Themes
$theme-bg-primary--bg: $primary;
$theme-bg-primary--text: $base-000;
$theme-bg-primary--link: $primary;
$theme-bg-primary--link-hover: $base-000;

$theme-bg-gray--link: $primary-dark;
$theme-bg-gray--link-hover: $base-000;

$theme-color--menu-link: $primary-dark;

$link-color: $var-link-color;
$link-hover-color: $var-link-hover-color;
$link-decoration: none;
$link-hover-decoration: none;

$links-transition: color 0.2s linear, opacity 0.2s linear, border 0.2s linear;

$env: 'prod' !default;

$debug-texts: false !default;

$spacer: 0.5rem;

$grid-breakpoints: (
    xs: 0,
    sm: 375px,
    md: 768px,
    lg: 992px,
    xl: 1280px,
    xxl: 1440px,
);

$grid-cols: (
    xs: (
        3,
        4,
        8,
        10,
        11,
        12,
    ),
    md: (
        5,
        6,
        10,
        12,
    ),
    lg: (
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
    ),
    xl: (
        5,
        6,
        8,
        9,
    ),
    xxl: (
        4,
        5,
        6,
        7,
        8,
    ),
);

$grid-orders: (
    md: (
        1,
        2
    ),
    lg: (
        1,
        2,
        3,
        4,
        5,
    ),
);

$grid-offsets: (
    lg: (
        1,
        2,
        3,
        6,
        4,
        5,
    ),
    xl: (
        1,
    ),
    xxl: (
        0,
        4,
    ),
);

$grid-row-columns: 0;

$spaces: (
    modulor: (
        null: 8,
        xxl: 4,
    ),
    tiny: (
        null: 8,
    ),
    xxs: (
        null: 12,
    ),
    xs: (
        null: 16,
    ),
    sm: (
        null: 16,
        xxl: 20,
    ),
    md: (
        null: 24,
    ),
    lg: (
        null: 24,
        xxl: 32,
    ),
    xg: (
        null: 32,
        xxl: 40,
    ),
    xl: (
        null: 32,
        lg: 40,
        xxl: 48,
    ),
    xxl: (
        null: 40,
        lg: 48,
        xxl: 64,
    ),
    xxxl: (
        null: 64,
        lg: 80,
        xxl: 96,
    ),
    huge: (
        null: 80,
        lg: 96,
        xxl: 128,
    ),
    mega: (
        null: 80,
        lg: 128,
        xxl: 182,
    ),
    grid-small: (
        null: 20,
        lg: 24,
    ),
    grid-layout: (
        null: 16,
        lg: 24,
        xxl: 40,
    ),
    grid-padding: (
        null: 20,
        lg: 32,
        xxl: 40,
    ),
    grid-margin: (
        null: 20,
        lg: 32,
        xxl: 64,
    ),
    auto: (
        null: 'auto',
    ),
    none: (
        null: 0,
    ),
) !default;

$section-paddings: () !default;

$font-weights: (
    semi-bold: 600,
) !default;

/**
   * @tokens 00 Config II — @solublestudio/soluto-design-system
   */
$sizes: ();
$sizes: map-merge(
    (
        100: 100%,
        auto: auto,
    ),
    $sizes
) !default;

$displays: none, inline-block, block, flex, inline-flex !default;
$positions: relative, absolute, fixed !default;

$scroll-behavior: smooth;
$font-smoothing-antialised: true;

$flex-styles: (
    direction: (
        null: (
            column,
            column-reverse,
        ),
        md: (
            row,
            column,
        ),
        lg: (
            row,
            column,
        ),
        xl: (
            row,
            column,
        ),
    ),
    wrap: (),
    justify-content: (
        null: (
            center,
            start,
            end,
            between,
        ),
        md: (
            between,
            center,
            end,
        ),
        lg: (
            between,
            center,
            end,
        ),
        xl: (
            end,
            center,
        ),
    ),
    align-items: (
        null: (
            end,
            center,
            start,
        ),
        md: (
            start,
            end,
            center,
        ),
        lg: (
            start,
            end,
            center,
        ),
    ),
) !default;

$rounded-styles: () !default;

// Font variables
$font-family-base: 'Matter', sans-serif;

$font-styles: (
    h400: (
        null: (
            font-size: 54px,
            letter-spacing: -1px,
            line-height: 60px,
        ),
        lg: (
            letter-spacing: -1.6px,
        ),
        xxl: (
            font-size: 76px,
            letter-spacing: -2px,
            line-height: 84px,
        ),
    ),
    h300: (
        null: (
            font-size: 36px,
            letter-spacing: -1px,
            line-height: 48px,
        ),
        lg: (
            font-size: 40px,
        ),
        xxl: (
            font-size: 56px,
            line-height: 68px,
        ),
    ),
    h200: (
        null: (
            font-size: 28px,
            letter-spacing: -0.2px,
            line-height: 36px,
        ),
        lg: (
            font-size: 34px,
            line-height: 40px,
        ),
        xxl: (
            font-size: 41px,
            line-height: 56px,
            letter-spacing: -0.5px,
        ),
    ),
    h100: (
        null: (
            font-size: 23px,
            line-height: 32px,
        ),
        lg: (
            font-size: 21px,
            letter-spacing: -0.2px,
            line-height: 28px,
        ),
        xxl: (
            font-size: 30px,
            letter-spacing: 0,
            line-height: 40px,
        ),
    ),
    b400: (
        null: (
            font-size: 19px,
            line-height: 28px,
        ),
        lg: (
            font-size: 18px,
        ),
        xxl: (
            font-size: 21px,
            line-height: 32px,
        ),
    ),
    b300: (
        null: (
            font-size: 17px,
            line-height: 24px,
        ),
        lg: (
            font-size: 16px,
        ),
        xxl: (
            font-size: 18px,
            line-height: 28px,
        ),
    ),
    b200: (
        null: (
            font-size: 15px,
            line-height: 20px,
        ),
        lg: (
            line-height: 24px,
        ),
        xxl: (
            font-size: 16px,
        ),
    ),
    b100: (
        null: (
            font-size: 13px,
            line-height: 20px,
        ),
        lg: (
            font-size: 14px,
        ),
    ),
    b050: (
        null: (
            font-size: 12px,
            line-height: 18px,
        ),
    ),
) !default;

$button-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$button-svg-transition: transform 0.15s ease-in-out !default;

$buttonLargeSizes: (
    null: (
        border-radius: 50px,
        border-width: 1px,
        font-family: $font-family-base,
        font-size: 17px,
        font-weight: 600,
        line-height: 24px,
        padding-x: 24px,
        padding-y: 20px,
    ),
    lg: (
        font-size: 16px,
    ),
    xxl: (
        font-size: 18px,
        line-height: 28px,
        padding-x: 32px,
    ),
);

$buttonSmallText: (
    null: (
        font-family: $font-family-base,
        font-size: 13px,
    ),
    lg: (
        font-size: 14px,
    ),
   
);

$buttonSmallSizes: (
    null: (
        border-radius: 50px,
        border-width: 1px,
        font-family: $font-family-base,
        font-size: 15px,
        font-weight: 600,
        line-height: 24px,
        padding-x: 16px,
        padding-y: 8px,
    ),
    xxl: (
        font-size: 16px,
    ),
);

$buttonIconSizes: (
    null: (
        font-family: $font-family-base,
        font-size: 15px,
        font-weight: normal,
        line-height: 24px,
    ),
    lg: (
        font-size: 16px,
    ),
);

$button-custom: (
    primaryLarge: (
        background: $primary,
        border: transparent,
        color: $primary-darker,
        hover-background: $primary-darker,
        hover-border: $primary-darker,
        hover-color: $base-1000,
        active-background: $primary-darker,
        active-border: $primary-darker,
        active-color: $base-1000,
        disabled-background: $base-300,
        disabled-border: $base-300,
        disabled-color: $base-1000,
        sizes: $buttonLargeSizes,
    ),
    primarySmall: (
        background: $primary,
        border: $primary,
        color: $primary-darker,
        hover-background: $primary-darker,
        hover-border: $primary-darker,
        hover-color: $base-1000,
        active-background: $primary-darker,
        active-border: $primary-darker,
        active-color: $base-1000,
        disabled-background: $base-300,
        disabled-border: $base-300,
        disabled-color: $base-1000,
        sizes: $buttonSmallSizes,
    ),
    secondaryLarge: (
        background: $base-1000,
        border: $primary-darker,
        color: $primary-darker,
        hover-background: $primary-darker,
        hover-border: $primary-darker,
        hover-color: $base-1000,
        active-background: $primary-darker,
        active-border: $primary-darker,
        active-color: $base-1000,
        disabled-background: $base-1000,
        disabled-border: $base-300,
        disabled-color: $base-300,
        sizes: $buttonLargeSizes,
    ),
    secondaryLargeWithOutBorder: (
        background: $base-1000,
        border: transparent,
        color: $primary-darker,
        hover-background: $primary-darker,
        hover-border: transparent,
        hover-color: $base-1000,
        active-background: $primary-darker,
        active-border: transparent,
        active-color: $base-1000,
        disabled-background: $base-1000,
        disabled-border: transparent,
        disabled-color: $base-300,
        sizes: $buttonLargeSizes,
    ),
    secondarySmall: (
        background: $base-1000,
        border: $primary-darker,
        color: $primary-darker,
        hover-background: $primary-darker,
        hover-border: $primary-darker,
        hover-color: $base-1000,
        active-background: $primary-darker,
        active-border: $primary-darker,
        active-color: $base-1000,
        disabled-background: $base-1000,
        disabled-border: $base-300,
        disabled-color: $base-300,
        sizes: $buttonSmallSizes,
    ),
    buttonIcon: (
        background: transparent,
        color: $primary-darker,
        hover-color: $primary-darker,
        active-color: $primary-darker,
        disabled-color: $base-300,
        sizes: $buttonIconSizes,
    ),
    buttonIconLight: (
        background: transparent,
        color: $base-1000,
        hover-color: $base-1000,
        active-color: $base-1000,
        disabled-color: $base-300,
        sizes: $buttonIconSizes,
    ),
    buttonIconBorder: (
        background: transparent,
        color: $primary-dark,
        hover-color: $primary-darker,
        active-color: $primary-darker,
        disabled-color: $base-300,
        sizes: $buttonIconSizes,
    ),
    buttonIconSingle: (
        color: currentColor,
        disabled-color: $base-300,
        sizes: (
            null: (
                padding-x: 0,
                padding-y: 0,
            ),
        ),
    ),
    link:(
        color: $primary-darker,
        hover-color: $primary-dark,
        active-color: $primary-dark,
        disabled-color: $base-300,
        sizes: $buttonLargeSizes,
    ),
    linkSmall:(
        color: $base-000,
        hover-color: $primary-dark,
        active-color: $primary-dark,
        disabled-color: $base-300,
        sizes: $buttonSmallText,
    ),
    menuItem:(
        color: $base-000,
        hover-color: $primary-dark,
        active-color: $primary-dark,
        disabled-color: $base-300,
        sizes: (
            null: (
                padding-x: 0,
                padding-y: 0,
            ),
        ),
    )
);

$table-border-width: 0;
$table-cell-padding: 0;
$table-dark-bg: transparent;

$html-components-fonts: (
    p: (
        'b300',
    ),
    h1: (
        'h300',
    ),
    h2: (
        'h200',
    ),
    h3: (
        'h100',
    ),
    h4: (
        'b400',
    ),
    h5: (
        'b300',
    ),
    h6: (
        'b300',
    ),
    li: (
        'b300',
    ),
);

$html-components-margins: (
    p: (
        null: (
            0px,
            16px,
        ),
        lg: (
            0px,
            24px,
        ),
    ),
    h2: (
        null: (
            24px,
            24px,
        ),
    ),
    h3: (
        null: (
            16px,
            8px,
        ),
        lg: (
            24px,
            8px,
        ),
    ),
    ul: (
        null: (
            0px,
            16px,
        ),
        lg: (
            0px,
            24px,
        ),
    ),
    ol: (
        null: (
            0px,
            16px,
        ),
        lg: (
            0px,
            24px,
        ),
    ),
);
$html-link-color: $primary-dark;
$html-link-text-decoration: none;
$html-hover-link-text-decoration: none;
$html-hover-link-color: none;
$html-link-color-darkmode: null !default;
$html-hover-link-color-darkmode: null !default;
$html-padding-list-points: 3rem;

$html-table-style: 'table';
$html-table-dark: false;
$html-table-small: null !default;

// Card variables

$card-custom: (
    default: (
        sizes: (
            lg: (
                horizontal-right: true,
            ),
        ),
    ),
) !default;

// Navbar
$navbar-speed: 200ms;
$navbar-collapse-stickybottom-position: 0;
$navbar-menu-burger-dropdown-height: 40vh;
$navbar-content-bar-max-height: 45vh;
$navbar-collapse-menu-burger-height: 70vh;
$navbar-collapse-menu-burger-transform: -70vh;
$navbar: ();
$navbar-offset: (
    null: 40,
    md: 144,
    xxl: 253
);

$header-speed: 200ms;
$header-custom: (
    default: (
        // full-width: true,
        collapse:
            (
                // full-height: true,
                breakpoint: lg,
                padding-y: lg,
                // hide-header: false,
                colors:
                    (
                        null: (
                            background-color: $base-1000,
                            //navigation-item-color: $base-000,
                            //logo: black
                            //dropdown-item-color: $var-bg-color,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
                        ),
                    )
            ),
        colors: (
            null: (
                logo: black,
                background-color: $base-1000,
                navigation-item-color: $base-000,
                // navigation-item-color-hover: red,
                // navigation-item-color-active: brown,
                dropdown-background-color: $base-1000,
                dropdown-item-color: $var-text-color,
                dropdown-item-color-hover: $primary-dark,
                dropdown-item-color-active: $primary-dark,
            ),
        ),
        sizes: (
            null: (
                height: 80px,
                hamburger-size: 32px,
                // padding-x: huge,
                padding-y: lg,
                logo-height: 32px,
                // logo-width: auto
                navigation-item-separation: xg,
                navigation-font-style: 'h100',
                navigation-font-weight: 600,
                dropdown-icon-size: 24px,
                dropdown-item-separation: xs,
                dropdown-padding-y: xs,
            ),
            lg: (
                padding-y: none,
                navigation-item-separation: xs,
                navigation-font-style: 'b200',
                logo-height: 24px,
                dropdown-shadow: 0px 32px 32px rgba(0, 0, 0, 0.1),
            ),
            xxl: (
                logo-height: 40px,
                navigation-item-separation: lg,
                navigation-font-style: 'b300',
            ),
        ),
        sticky: (
            colors: (
                null: (
                    logo: black,
                    background-color: $base-1000,
                    navigation-item-color: $base-000,
                ),
            ),
        ),
    ),
    simple: (
        collapse:
            (
                breakpoint: lg,
                padding-y: lg,
                colors:
                    (
                        null: (
                            background-color: $base-1000,
                        ),
                    )
            ),
        colors: (
            null: (
                logo: logoMobile,
                background-color: $base-1000,
            ),
            md: (
                logo: black,
            ),
        ),
        sizes: (
            null: (
                height: 80px,
                hamburger-size: 32px,
                padding-y: lg,
                logo-height: 32px,
            ),
            lg: (
                padding-y: none,
                logo-height: 24px,
            ),
            xxl: (
                logo-height: 40px,
            ),
        )
    ),
);

// Breadcrumb

$breadcrumb-divider-background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.46712 5L16.4671 12L9.46712 19L8 17.5329L13.5329 12L8 6.46712L9.46712 5Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
$breadcrumb-bg: transparent;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-divider-color: $var-text-color;
$breadcrumb-active-color: $var-text-color;
$breadcrumb-divider-size: 12px;

// Tabs variables

// $nav-tabs-padding-x: null !default;
// $nav-tabs-padding-y: null !default;
// $nav-tabs-padding-x-lg: null !default;
// $nav-tabs-padding-y-lg: null !default;
// $nav-tabs-padding-x-xxl: null !default;
// $nav-tabs-padding-y-xxl: null !default;

/**
   * @tokens 40 Forms — @solublestudio/soluto-design-system
   */

$border-radius: 0;

$input-height: 3.75rem;
$input-font-size: 1.125rem;
$input-line-height: 1.56;
$input-padding-y: 1rem;
$input-padding-x: 1.25rem;
$input-border-radius: 1.875rem;

$input-height-sm: 3rem;
$input-font-size-sm: 0.9375rem;
$input-line-height-sm: 1.6;
$input-padding-y-sm: 0.75rem;
$input-padding-x-sm: 1rem;
$input-border-radius-sm: 1.5rem;

$input-box-shadow: none;
$input-focus-box-shadow: $input-box-shadow;
$input-focus-width: 0;
$input-transition: none;

//$input-placeholder-font-style: null !default;
$input-placeholder-color: $base-200;
$input-bg: $base-1000;
$input-color: $base-000;
$input-border-color: $base-300;
$input-focus-border-color: $input-border-color;
$input-border-width: 0.0625rem;
$input-focus-box-shadow: $input-box-shadow;

$input-disabled-bg: transparent;

$form-text-margin-top: 0.125rem;
$form-feedback-font-size: 0.875rem;
$enable-validation-icons: false;

$form-validation-states: (
    'invalid': (
        'color': $support-error,
    ),
    'valid': (
        'color': $primary,
    ),
);

$form-group-margin-bottom: 24px;

$enable-validation-icons: false;

$input-font-styles: 'b300';
$label-font-styles: 'b100';
// $submit-button-type: null !default;

// $input-eraser-background: null !default;
// $input-eraser-size: null !default;

// $custom-select-lg-padding-y: null !default;
// $custom-select-lg-padding-x: null !default;
// $custom-select-lg-line-height: null !default;

// $custom-control-gutter: 0.5rem !default;
// $custom-control-spacer-x: 1rem !default;
// $custom-control-cursor: null !default;

$custom-control-indicator-size: 1rem;
// $custom-control-indicator-bg: null !default;

// $custom-control-indicator-bg-size: 100% 100% !default;
$custom-control-indicator-border-color: $base-000;
$custom-control-indicator-border-width: 2px;

// $custom-control-label-color: null !default;

// $custom-control-indicator-checked-color: null !default;
// $custom-control-indicator-checked-bg: null !default;
// $custom-control-indicator-checked-border-color: null !default;
// $custom-control-indicator-focus-border-color: null !default;

// $custom-control-indicator-active-color: null !default;
// $custom-control-indicator-active-bg: null !default;
// $custom-control-indicator-active-border-color: null !default;

// $custom-checkbox-indicator-icon-checked: null !default;

// $custom-radio-indicator-icon-checked: null !default;

// $form-group-lg-margin-bottom: null !default;

/**
   * @tokens 80 Misc — @solublestudio/soluto-design-system
   */
$paragraph-margin-bottom: 0;
$headings-margin-bottom: 0;

$alert-padding-x: 1rem !default;

$accordion-arrow-icon-bg: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z' fill='%23111111'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z' fill='%23111111'/%3E%3C/svg%3E");
$accordion-arrow-icon-size: 24px;

$tooltip-margin: 0;
$tooltip-shadow: null !default;
$tooltip-max-width-lg: null !default;
$tooltip-padding-y-lg: null !default;
$tooltip-padding-x-lg: null !default;

$pagination-font-name: null !default;
$pagination-separation: null !default;
$pagination-border-radius: null !default;

/**
   * @tokens 10 Grid — @solublestudio/soluto-design-system
   */
$grid-margin: 20px;
$grid-margin-md: 20px;
$grid-margin-lg: 32px;
$grid-margin-xl: 32px;
$grid-margin-xxl: 64px;

$grid-gutter-width: 20px;
$grid-gutter-width-md: 20px;
$grid-gutter-width-lg: 24px;
$grid-gutter-width-xl: 24px;
$grid-gutter-width-xxl: 40px;
// $grid-gutter-width-md: $grid-gutter-width !default;
// $grid-gutter-width-lg: $grid-gutter-width !default;
// $grid-gutter-width-xl: $grid-gutter-width !default;
// $grid-gutter-width-xxl: $grid-gutter-width !default;

// $container-max-widths: (
//     sm: map-get($grid-breakpoints, 'sm') + $grid-gutter-width,
//     md: map-get($grid-breakpoints, 'md') + $grid-gutter-width,
//     lg: map-get($grid-breakpoints, 'lg') + $grid-gutter-width,
//     xl: map-get($grid-breakpoints, 'xl') + $grid-gutter-width-xl,
//     xxl: map-get($grid-breakpoints, 'xxl') + $grid-gutter-width-xxl,
// );

// $container-max-widths-scrollbar: (
//     sm: map-get($container-max-widths, 'sm') - 15px,
//     md: map-get($container-max-widths, 'md') - 15px,
//     lg: map-get($container-max-widths, 'lg') - 15px,
//     xl: map-get($container-max-widths, 'xl') - 15px,
//     xxl: map-get($container-max-widths, 'xxl') - 15px,
// );

// SAL
$sal-animation-duration: 0.8s;
//$sal-animation-delay: 0s;
//$sal-slide-offset: 20%;
//$sal-zoom-in-scale: 0.5;
//$sal-zoom-out-scale: 1.1;
//$sal-flip-rotate: 91deg;
//$sal-default-easing: cubic-bezier(0.165, 0.84, 0.44, 1);
$sal-delays: (
    200: (
        null: 200ms,
    ),
    400: (
        null: 400ms,
    ),
    600: (
        null: 600ms,
    ),
    800: (
        null: 800ms,
    ),
    1000: (
        null: 1000ms,
    ),
    1200: (
        null: 1200ms,
    ),
    1400: (
        null: 1400ms,
    ),
    1500: (
        null: 1500ms,
    ),
    1600: (
        null: 1600ms,
    ),
    1800: (
        null: 1800ms,
    ),
    2000: (
        null: 2000ms,
    ),
    2200: (
        null: 2200ms,
    ),
    2400: (
        null: 2400ms,
    ),
    3000: (
        null: 3000ms,
    ),
);

$sal-durations: (
    200: (
        null: 200ms,
    ),
    1200: (
        null: 1200ms,
    ),
);

$icon-sizes: (8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 64);

// Modal

$modal-inner-padding: 24px 16px;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
// $modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 0;
$modal-dialog-margin-y-sm-up: 0;

//$modal-title-line-height: $line-height-base;

$modal-content-color: $base-000;
$modal-content-bg: $base-1000;

$modal-content-border-color: transparent;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;

$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;

$modal-backdrop-bg: $base-000;
$modal-backdrop-opacity: 0.15;

$modal-header-border-color: rgba($base-000, 0.2);
//$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: 0;
//$modal-footer-border-width: $modal-header-border-width;

$modal-header-padding-y: 0;
$modal-header-padding-x: 0;
$modal-header-padding: 0;

$modal-xl: 100%;
$modal-lg: 699px;
$modal-md: 493px;
$modal-sm: 335px;
//$modal-md: 500px;

$modal-fade-transform: translate(0, 100%);
$modal-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
$transition-fade: $modal-transition;
$modal-show-transform: none;
//$modal-scale-transform: scale(1.02);
