// Mixins

// Link Mixins
@mixin linksStyles {
    border-bottom: 1px solid transparent;
    transition: $links-transition;

    &:hover:not(:disabled),
    &:focus,
    &:active {
        border-color: currentColor;
    }
}
